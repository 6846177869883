<template>
  <div>
    <b-card class="col-12 d-flex flex-column" no-body style="min-height: 85vh">
      <div
        style="
          height: 100vh;
          width: 100vw;
          position: fixed;
          top: 0;
          z-index: 999;
          background-color: rgba(22, 29, 49, 0.63);
          opacity: 1;
          margin-left: -1.5rem;
        "
        v-show="loader"
      >
        <img
          :src="require('@/assets/loader.svg')"
          style="margin-left: 43%; margin-top: 23%"
        />
      </div>

      <b-tabs class="mt-2">
        <b-tab
          v-for="item in searchOptions"
          :key="item"
          :title="item"
          @click="tabFunction(item)"
        >
          <template #title>
            <feather-icon
              :icon="
                item == CalendarIcon
                  ? 'CalendarIcon'
                  : item == BarChartIcon
                  ? 'BarChartIcon'
                  : 'TruckIcon'
              "
            />
            <span>{{ item }}</span>
          </template>
        </b-tab>
      </b-tabs>
      <b-list-group class="my-2">
        <b-list-group-item>
          <b-row align-v="center" >
            <b-col md="4">
              <b-row align-v="center">
                <b-col md="3" style="padding-left: 0px">
                  <label for="" style="
                      font-size: 1rem;
                      white-space: normal;
                      overflow-wrap: break-word;
                      word-break: normal;
                    ">{{
                    $t("label.L0017")
                  }}</label>
                </b-col>
                <b-col md="8">
                  <v-select
                    v-model="tunnelCodeStatus"
                    :clearable="false"
                    label="text"
                    :options="sortedItems(tunnelCodeOptions)"
                    return-object
                  />
                </b-col>
              </b-row>
            </b-col>
            <b-col md="4">
              <b-row align-v="center">
                <b-col md="2" style="padding-left: 0px">
                  <label for="" style="
                      font-size: 1rem;
                      white-space: normal;
                      overflow-wrap: break-word;
                      word-break: normal;
                    ">{{
                    $t("label.L0047")
                  }}</label>
                </b-col>
                <b-col md="10">
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <feather-icon icon="CalendarIcon" />
                    </b-input-group-prepend>
                    <flat-pickr
                      v-model="mergeDate"
                      ref="dateFrom"
                      class="form-control"
                      :placeholder="$t('message.M0007')"
                      :config="getCurrentConfig"
                      :key="flatPickrConfigMode"
                    />
                    <!-- <flat-pickr
                      v-model="mergeDate"
                      ref="dateFrom"
                      class="form-control"
                      placeholder="날짜를 선택하세요."
                      :config="searchType === 'trafficInfo' && changeChartType === 'trafficAvgSpeed' ? timeFlatPickrConfig : flatPickrConfig"
                    /> -->
                    <b-input-group-append>
                      <b-button variant="primary" @click="doFilter">{{
                        $t("button.B0008")
                      }}</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-col>
            <!-- <b-col md="4">
              <b-row align-v="center">
                <label for="" style="font-size: 1rem; padding-right: 2em">{{
                  $t("label.L0107")
                }}</label>
                <b-form-radio-group
                  v-model="changeDateType"
                  :options="dateOptions"
                  name="radio-validation"
                >
                </b-form-radio-group>
                <b-col>
                  <b-button
                    variant="primary"
                    style="font-size: 1rem; margin-right: 2em"
                    @click="rEventTypeUpdateBtn()"
                    >{{ $t("label.L0098") }}
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="1" align="right">
              <b-button class="addBtn" variant="primary" @click="downloadFile()">
                {{$t("label.L0108")}}
              </b-button>
            </b-col> -->
          </b-row>
        </b-list-group-item>
      </b-list-group>
      <b-row v-if="!isShow && searchType == 'trafficInfo'">
        <b-col md="3">
          <b-button-group size="sm" class="mb-1">
            <b-button
              v-model="changeChartType"
              @click="changeChartType = 'trafficCount'; tabFunction($t('label.L0084'))"
              :pressed="changeChartType == 'trafficCount' ? true : false"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
            >
            {{$t("label.L0085")}}
            </b-button>
            <b-button
              v-model="changeChartType"
              @click="changeChartType = 'trafficAvgSpeed'; tabFunction($t('label.L0084'))"
              :pressed="changeChartType == 'trafficAvgSpeed' ? true : false"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
            >
              평균속도
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>
      <b-row class="padding-base-12 flex-grow-1">
        <VueApexCharts
          v-show="!isShow && searchType == 'occurEvent'"
          style="width: 100%"
          height="550"
          type="bar"
          :options="occurEventChartOptions"
          :series="occurEventData"
        ></VueApexCharts>
        <VueApexCharts
          v-show="
            !isShow &&
            searchType == 'trafficInfo' &&
            changeChartType == 'trafficCount'
          "
          style="width: 65%"
          type="line"
          :options="trafficCountChartOptions"
          :series="trafficCountData"
        ></VueApexCharts>
        <VueApexCharts
          v-show="
            !isShow &&
            searchType == 'trafficInfo' &&
            changeChartType == 'trafficAvgSpeed'
          "
          style="width: 65%"
          type="line"
          :options="trafficAvgSpeedChartOptions"
          :series="trafficAvgSpeedData"
        ></VueApexCharts>
        <b-table
          sticky-header
          v-if="searchType !== 'occurEvent'"
          ref="maintable"
          striped
          hover
          responsive
          :items="getList"
          :fields="fields"
          :sort-by.sync="sortValue"
          sort-desc.sync="true"
          :filter="filter"
          :filter-included-fields="filterOn"
          :per-page="rowNum"
          :current-page="currentPage"
          show-empty
          :empty-text="$t('message.M0008')"
          empty-filtered-text="검색어와 일치하는 결과가 없습니다."
          class="mb-0 text-center"
          :style="{
            width: searchType === 'trafficInfo' ? '35%' : '',
            maxHeight: '100%'
          }"
        >
          <template #cell(idx)="data">
            {{ data.index + 1 + (currentPage - 1) * 10 }}
          </template>
          <template #cell(occurDate)="data">
            {{ changeOcrnDt(data.item.occurDate) }}
          </template>
          <template #cell(trafficCount_occurDate)="data">
            {{ changeOcrnDt(data.item.detect_time) }}
          </template>
          <template #cell(trafficAvgSpeed_occurDate)="data">
            {{ data.item.detect_time }}
          </template>
          <template #cell(direction)>
            <div style="padding-bottom: 0.6em">
              <!-- {{ data.item.direction.split(", ")[0] }} -->
              {{$t("label.L0019")}}
            </div>
            <hr />
            <div style="padding-top: 0.6em">
              <!-- {{ data.item.direction.split(", ")[1] }} -->
              {{$t("label.L0020")}}
            </div>
          </template>
          <template #cell(type)>
            <b-badge pill variant="light-success" style="margin-bottom:0.5rem;">{{$t("label.L0109")}}</b-badge><br />
            <b-badge pill variant="light-danger">{{$t("label.L0110")}}</b-badge><br />
            <hr style="margin-bottom:1rem;" />
            <b-badge pill variant="light-success" style="margin-bottom:0.5rem;">{{$t("label.L0109")}}</b-badge><br />
            <b-badge pill variant="light-danger">{{$t("label.L0110")}}</b-badge><br />
          </template>
          <template #cell(total)="data">
            <div class="text-right">
              {{ getTotalCaseTrueStart(data) }}<br />
              {{ getTotalCaseFalseStart(data) }}<br />
              <hr />
              {{ getTotalCaseTrueEnd(data) }}<br />
              {{ getTotalCaseFalseEnd(data) }}<br />
            </div>
          </template>
          <template #cell(case1)="data">
            <div class="text-right">
              {{ data.item.case1trueStart }}<br />
              {{ data.item.case1falseStart }}<br />
              <hr />
              {{ data.item.case1trueEnd }}<br />
              {{ data.item.case1falseEnd }}<br />
            </div> 
          </template>
          <template #cell(case2)="data">
            <div class="text-right">
              {{ data.item.case2trueStart }}<br />
              {{ data.item.case2falseStart }}<br />
              <hr />
              {{ data.item.case2trueEnd }}<br />
              {{ data.item.case2falseEnd }}<br />
            </div> 
          </template>
          <template #cell(case3)="data">
            <div class="text-right">
              {{ data.item.case3trueStart }}<br />
              {{ data.item.case3falseStart }}<br />
              <hr />
              {{ data.item.case3trueEnd }}<br />
              {{ data.item.case3falseEnd }}<br />
            </div>  
          </template>
          <template #cell(case4)="data">
            <div class="text-right">
              {{ data.item.case4trueStart }}<br />
              {{ data.item.case4falseStart }}<br />
              <hr />
              {{ data.item.case4trueEnd }}<br />
              {{ data.item.case4falseEnd }}<br />
            </div>  
          </template>
          <!-- <template #cell(case6)="data">
            <div class="text-right">
              {{ data.item.case6trueStart }}<br />
              {{ data.item.case6falseStart }}<br />
              <hr />
              {{ data.item.case6trueEnd }}<br />
              {{ data.item.case6falseEnd }}<br />
            </div> 
          </template> -->
          <template #cell(case9)="data">
            <div class="text-right">
              {{ data.item.case9trueStart }}<br />
              {{ data.item.case9falseStart }}<br />
              <hr />
              {{ data.item.case9trueEnd }}<br />
              {{ data.item.case9falseEnd }}<br />
            </div>  
          </template>
          <!-- <template #cell(case11)="data">
            <div class="text-right">
              {{ data.item.case11trueStart }}<br />
              {{ data.item.case11falseStart }}<br />
              <hr />
              {{ data.item.case11trueEnd }}<br />
              {{ data.item.case11falseEnd }}<br />
            </div>  
          </template>
          <template #cell(case12)="data">
            <div class="text-right">
              {{ data.item.case12trueStart }}<br />
              {{ data.item.case12falseStart }}<br />
              <hr />
              {{ data.item.case12trueEnd }}<br />
              {{ data.item.case12falseEnd }}<br />
            </div>  
          </template>
          <template #cell(case13)="data">
            <div class="text-right">
              {{ data.item.case13trueStart }}<br />
              {{ data.item.case13falseStart }}<br />
              <hr />
              {{ data.item.case13trueEnd }}<br />
              {{ data.item.case13falseEnd }}<br />
            </div>  
          </template>
          <template #cell(case14)="data">
            <div class="text-right">
              {{ data.item.case14trueStart }}<br />
              {{ data.item.case14falseStart }}<br />
              <hr />
              {{ data.item.case14trueEnd }}<br />
              {{ data.item.case14falseEnd }}<br />
            </div>  
          </template> -->
          <template #cell(count)="data">
            <div class="text-right">
              {{ data.item.start_sum.toLocaleString() }}<br />
              <hr />
              {{ data.item.end_sum.toLocaleString() }}<br />
            </div>  
          </template>
          <template #cell(speed)="data">
            <div class="text-right">
              {{ data.item.start_avg_speed.toFixed(2) }}<br />
              <hr />
              {{ data.item.end_avg_speed.toFixed(2) }}<br />
            </div>  
          </template>
        </b-table>
      </b-row>
      <b-row v-show="searchType !== 'occurEvent'" class="my-2" align-v="center" style="bottom: 0; width: 100%;">
        <b-col md="1" class="ml-1">
          <b-form-group class="mb-0">
            <v-select
              id="perPageSelect"
              v-model="rowNum"
              size="sm"
              :options="perPageOptions"
              :clearable="false"
            />
          </b-form-group>
        </b-col>
        <b-col>
          {{$t("label.L0021")}} {{ dataMeta.of }} {{$t("label.L0111")}} {{ dataMeta.from }} ~
          {{ dataMeta.to }} {{$t("label.L0112")}}
        </b-col>
        <b-col>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            first-number
            last-number
            :per-page="rowNum"
            @change="(temporaryItems = []), (allSelected = false)"
            align="right"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import dayjs from "dayjs";
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";
import { English } from "flatpickr/dist/l10n/default.js";
import { Korean } from "flatpickr/dist/l10n/ko.js";
import ConfirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate.js";
import statisticsInterface from "./statisticsInterface";
import Ripple from "vue-ripple-directive";
import {
  BRow,
  BCol,
  BFormRadio,
  BFormRadioGroup,
  BFormGroup,
  BTable,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroupPrepend,
  BFormSelect,
  BPagination,
  BButtonGroup,
  BBadge,
  BCard,
  BTabs,
  BTab,
  BListGroup,
  BListGroupItem,
  BFormCheckbox,
  BSpinner,
} from "bootstrap-vue";
export default {
  name: "DataRetentionPolicyManagement",
  components: {
    BRow,
    BCol,
    BFormRadio,
    BFormRadioGroup,
    BFormGroup,
    BTable,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroupPrepend,
    BFormSelect,
    BPagination,
    BButtonGroup,
    BBadge,
    BTabs,
    BTab,
    vSelect,
    BCard,
    BListGroup,
    BListGroupItem,
    flatPickr,
    BFormCheckbox,
    BSpinner,
    VueApexCharts,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      propTitle: "상태",
      isShow: true,
      flatPickrConfigMode: "default",
      items: [],
      fields: [],
      filterOn: [],
      temporaryItems: [],
      allSelected: false,
      sortValue: "",
      tempfilter: "",
      // searchOptions: [this.$t("label.L0082"), this.$t("label.L0083"), this.$t("label.L0084")],
      searchOptions: [this.$t("label.L0082"), this.$t("label.L0083")],
      mergeDate: "",
      dateSeparator: "",
      flatPickrConfig: {
        mode: "range",
        locale: this.$i18n.locale == 'ko' ? Korean : English,
        enableTime: false,
        // time_24hr : true, // 오전, 오후 없어짐
        allowInput: false, // 사용자 직접 입력 막기
        // allowInput: true,
        // time_24hr: true,
        dateFormat: "Y-m-d",
        maxDate: new Date(),
        // defaultHour: [5, 10],
        defaultDate: new dayjs().format("YYYY-MM-DD"),
        plugins: [
          new ConfirmDatePlugin({
            confirmIcon: "",
            confirmText: "OK", // 달력 버튼
            showAlways: true,
            theme: "light", // or "dark"
          }),
        ],
      },
      // 교통정보탭의 평균 속도 전용 FlatPick (현재 사용X)
      timeFlatPickrConfig: {
        mode: "range",
        locale: this.$i18n.locale == 'ko' ? Korean : English,
        enableTime: true,
        // time_24hr : true, // 오전, 오후 없어짐
        allowInput: false, // 사용자 직접 입력 막기
        // allowInput: true,
        // time_24hr: true,
        dateFormat: "Y-m-d H:i",
        maxDate: new Date(),
        defaultHour: [5, 10],
        // defaultDate: new dayjs().format("YYYY-MM-DD HH:mm"),
        plugins: [
          new ConfirmDatePlugin({
            confirmIcon: "",
            confirmText: "OK", // 달력 버튼
            showAlways: true,
            theme: "light", // or "dark"
          }),
        ],
      },
      changeDateType: "month",
      dateOptions: [
        { text: "당일", value: "today" },
        { text: "주간", value: "week" },
        { text: "한달", value: "month" },
      ],
      excelParam: null, // 엑셀 변수
      occurEventData: [], // 기간별 돌발발생 수 차트 데이터
      occurEventChartOptions: {
        // 기간별 돌발발생 수 차트 옵션
        chart: {
          id: "occurEvent",
          zoom: { enabled: false },
          toolbar: {
            show: false,
          }
        },
        title: {
          text: this.$t('label.L0114'),
          align: "left",
        },
        plotOptions: {
          bar: {
            distributed: true,
          },
        },
        theme: {
          palette: "palette10", // upto palette10
        },
        xaxis: {
          categories: [
            // this.$t('label.L0027'),
            this.$t('label.L0026'),
            this.$t('label.L0025'),
            this.$t('label.L0027'),
            this.$t('label.L0023'),
            this.$t('label.L0022'),
            // this.$t('label.L0024'),
            // this.$t('label.L0091'),
            // this.$t('label.L0092'),
            // this.$t('label.L0093'),
            // this.$t('label.L0094')
          ]
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value
                .toString()
                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            },
          },
        },
      },
      trafficCountData: [], // 기간별 차량대수 차트 데이터
      trafficCountChartOptions: {
        // 기간별 차량대수 차트 옵션
        chart: {
          height: 450,
          type: "line",
          stacked: false,
          toolbar: {
            show: false,
          },
        },
        title: {
          text: "기간 방면별 차량대수",
          align: "left",
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#FF1654", "#247BA0"],
        stroke: {
          width: [4, 4],
        },
        markers: {
          size: 4,
        },
        dataLabels: {
          enabled: false,
          enabledOnSeries: [0, 1],
        },
        plotOptions: {
          bar: {
            columnWidth: "20%",
            distributed: true,
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#FF1654",
            },
            labels: {
              style: {
                colors: "#FF1654",
              },
            },
            title: {
              text: "상행 차량대수",
              style: {
                color: "#FF1654",
                fontSize: "15px",
                fontWeight: "bold",
              },
            },
            labels: {
              formatter: function (value) {
                return value
                  .toString()
                  .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
              },
            },
          },
          {
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#247BA0",
            },
            labels: {
              style: {
                colors: "#247BA0",
              },
            },
            title: {
              text: "하행 차량대수",
              style: {
                color: "#247BA0",
                fontSize: "15px",
                fontWeight: "bold",
              },
            },
            labels: {
              formatter: function (value) {
                return value
                  .toString()
                  .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
              },
            },
          },
        ],
        tooltip: {
          shared: false,
          intersect: true,
          x: {
            show: false,
          },
        },
        legend: {
          horizontalAlign: "left",
          offsetX: 40,
        },
      },
      trafficAvgSpeedData: [], // 기간별 평균속도 차트 데이터
      trafficAvgSpeedChartOptions: {
        // 기간별 평균속도 차트 옵션
        chart: {
          height: 450,
          type: "line",
          stacked: false,
          toolbar: {
            show: false,
          },
        },
        title: {
          text: "기간 방면별 평균속도",
          align: "left",
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#FF1654", "#247BA0"],
        stroke: {
          width: [4, 4],
        },
        markers: {
          size: 4,
        },
        dataLabels: {
          enabled: false,
          enabledOnSeries: [0, 1],
        },
        plotOptions: {
          bar: {
            columnWidth: "20%",
            distributed: true,
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#FF1654",
            },
            labels: {
              style: {
                colors: "#FF1654",
              },
            },
            title: {
              text: "상행 평균속도",
              style: {
                color: "#FF1654",
                fontSize: "15px",
                fontWeight: "bold",
              },
            },
            labels: {
              formatter: function (value) {
                return value
                  .toString()
                  .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
              },
            },
          },
          {
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#247BA0",
            },
            labels: {
              style: {
                colors: "#247BA0",
              },
            },
            title: {
              text: "하행 평균속도",
              style: {
                color: "#FF1654",
                fontSize: "15px",
                fontWeight: "bold",
              },
            },
            labels: {
              formatter: function (value) {
                return value
                  .toString()
                  .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
              },
            },
          },
        ],
        tooltip: {
          shared: false,
          intersect: true,
          x: {
            show: false,
          },
        },
        legend: {
          horizontalAlign: "left",
          offsetX: 40,
        },
      },
      loader: false,
      CalendarIcon: this.$t('label.L0082'),
      BarChartIcon: this.$t('label.L0083'),
    };
  },
  setup() {
    const {
      refetchData,
      getList,
      getOccurEventChartData,
      occurEventChartData,
      trafficCountStatisticsList,
      getTrafficCountChartData,
      trafficCountChartData,
      getTrafficAvgSpeedChartData,
      trafficAvgSpeedChartData,
      dateTableColumns,
      techTableColumns,
      trafficCountTableColumns,
      trafficAvgSpeedTableColumns,
      rowNum,
      totalRows,
      currentPage,
      dataMeta,
      perPageOptions,
      filter,
      maintable,
      deleteFunction,
      updateFunction,
      tunnelCodeStatus,
      tunnelCodeOptions,
      searchBound,
      eventStatus,
      realEventStatus,
      judgment,
      searchType,
      searchStartDate,
      searchEndDate,
      linkCode,
      downloadExcelFunction,
      occurEventChartDataDownloadExcelFunction,
      trafficCountChartDataDownloadExcelFunction,
      trafficAvgSpeedChartDataDownloadExcelFunction,
      itemLength,
      downLoadFunction,
      updateRowFunction,
      getSelectBoxItems,
      changeChartType
    } = statisticsInterface();
    return {
      refetchData,
      getList,
      getOccurEventChartData,
      occurEventChartData,
      trafficCountStatisticsList,
      getTrafficCountChartData,
      trafficCountChartData,
      getTrafficAvgSpeedChartData,
      trafficAvgSpeedChartData,
      dateTableColumns,
      techTableColumns,
      trafficCountTableColumns,
      trafficAvgSpeedTableColumns,
      rowNum,
      totalRows,
      currentPage,
      dataMeta,
      perPageOptions,
      filter,
      maintable,
      deleteFunction,
      updateFunction,
      tunnelCodeStatus,
      tunnelCodeOptions,
      searchBound,
      eventStatus,
      realEventStatus,
      judgment,
      searchType,
      searchStartDate,
      searchEndDate,
      linkCode,
      downloadExcelFunction,
      occurEventChartDataDownloadExcelFunction,
      trafficCountChartDataDownloadExcelFunction,
      trafficAvgSpeedChartDataDownloadExcelFunction,
      itemLength,
      downLoadFunction,
      updateRowFunction,
      getSelectBoxItems,
      changeChartType
    };
  },
  methods: {
    sortedItems(items) {
      return items.slice().sort((a, b) => {
        if (a.text == 'All') return -1;
        if (b.text == 'All') return 1;
        // 이름을 기준으로 오름차순 정렬
        return a.text.localeCompare(b.text); // 음수: a가 b보다 앞, 0: a와 b가 동일, 양수: a가 b보다 뒤
      });
    },
    async init() {
      // 디폴트 날짜 ( 1달 )
      var dateFrom = dayjs().subtract(1, "week").format("YYYY-MM-DD");
      var dateTo = dayjs().format("YYYY-MM-DD");
      if (this.$i18n.locale == 'ko') {
        this.dateSeparator = ' ~ '
      } else {
        this.dateSeparator = ' to '
      }
      this.mergeDate = `${dateFrom}${this.dateSeparator}${dateTo}`; // 기간
      this.searchStartDate = dateFrom;
      this.searchEndDate = dateTo;
      this.fields = this.dateTableColumns;

      this.excelParam = {
        searchType: this.searchType,
        tunnelCode: this.tunnelCodeStatus.data,
        tunnelName: this.tunnelCodeStatus.text,
        startDate: this.searchStartDate,
        endDate: this.searchEndDate,
      };
    },
    async tabFunction(data) {
      this.loader = true;
      this.filter = "";
      this.tempfilter = "";
      this.currentPage = 1;
      this.sortValue = "idx";
      var dateFrom = dayjs(this.searchStartDate).format("YYYY-MM-DD");
      var dateTo = dayjs(this.searchEndDate).format("YYYY-MM-DD");
      this.mergeDate = `${dateFrom}${this.dateSeparator}${dateTo}`; // 기간
      this.searchStartDate = dateFrom;
      this.searchEndDate = dateTo;
      if (data === this.$t("label.L0082")) {
        this.searchType = "occurTime";
        this.fields = this.dateTableColumns;
        this.isShow = true;
        // } else if (data === '장비') {
        //   this.filter=''
        //   this.tempfilter=''
        //   this.currentPage=1
        //   this.searchType = "tech";
        //   this.fields = this.techTableColumns;
        //   this.sortValue = 'idx'
        //   this.isShow = true;
      } else {
        {
          this.isShow = false;
          if (data === this.$t("label.L0083")) {
            this.searchType = "occurEvent";
            await this.getOccurEventChartData();
            await this.setOccurEventChartData();
          } else if (
            data === this.$t("label.L0084") &&
            this.changeChartType === "trafficCount"
          ) {
            this.searchType = "trafficInfo";
            this.fields = this.trafficCountTableColumns;
            await this.getTrafficCountChartData();
            await this.setTrafficCountChartData();
          } else if (
            data === this.$t("label.L0084") &&
            this.changeChartType === "trafficAvgSpeed"
          ) {
            this.searchType = "trafficInfo";
            this.fields = this.trafficAvgSpeedTableColumns;
            dateFrom = dayjs(this.searchStartDate).format("YYYY-MM-DD HH:mm");
            dateTo = dayjs().format("YYYY-MM-DD HH:mm");
            // dateTo = dayjs(this.searchEndDate).format("YYYY-MM-DD") + " " + dayjs().format("HH:mm"); // searchEndDate의 날짜와 현재 시간으로 변경
            this.mergeDate = `${dateFrom}${this.dateSeparator}${dateTo}`; // 기간
            this.searchStartDate = dateFrom;
            this.searchEndDate = dateTo;
            await this.getTrafficAvgSpeedChartData();
            await this.setTrafficAvgSpeedChartData();
          }
        }
      }
      this.excelParam = {
        searchType: this.searchType,
        tunnelCode: this.tunnelCodeStatus.data,
        tunnelName: this.tunnelCodeStatus.text,
        startDate: this.searchStartDate,
        endDate: this.searchEndDate,
      };
      this.loader = false;
      this.refetchData();
    },
    changeOcrnDt(value) {
      const year = value.slice(0, 4);
      const month = value.slice(4, 6);
      const day = value.slice(6, 8);
      const hour = value.slice(8, 10);
      return `${year}-${month}-${day} ${hour}`;
    },
    getCurrentDate() {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var hour = date.getHours();
      var minute = date.getMinutes();
      var second = date.getSeconds();

      month = month < 10 ? "0" + month : month;
      day = day > 9 ? day : "0" + day;
      hour = hour > 9 ? hour : "0" + hour;
      minute = minute > 9 ? minute : "0" + minute;
      second = second > 9 ? second : "0" + second;

      return (
        year.toString() +
        month.toString() +
        day.toString() +
        hour.toString() +
        minute.toString() +
        second.toString()
      );
    },
    async doFilter() {
      this.loader = true;
      let mergeDateSplit = this.mergeDate.split(this.dateSeparator)
      
      this.searchStartDate = mergeDateSplit[0];
      if (mergeDateSplit[1] == null) {
        this.searchEndDate = this.searchStartDate;
      } else {
        this.searchEndDate = mergeDateSplit[1];
      }
      this.currentPage = 1;
      this.excelParam = {
        // 엑셀 파라미터 변수에 담기
        searchType: this.searchType,
        tunnelCode: this.tunnelCodeStatus.data,
        tunnelName: this.tunnelCodeStatus.text,
        startDate: this.searchStartDate,
        endDate: this.searchEndDate,
      };
      await this.getOccurEventChartData();
      await this.setOccurEventChartData();
      await this.getTrafficCountChartData();
      await this.setTrafficCountChartData();
      await this.getTrafficAvgSpeedChartData();
      await this.setTrafficAvgSpeedChartData();

      this.loader = false;
      await this.refetchData();
    },
    async downloadFile() {
      this.loader = true;
      var name = this.tunnelCodeStatus.text;
      var response = null;
      if (this.searchType == "occurTime") {
        var fileName = name + "_통계_리포트_" + this.getCurrentDate() + ".xlsx";
        response = await this.downloadExcelFunction(this.excelParam, fileName);
      } else if (this.searchType == "occurEvent") {
        var fileName =
          name + "_돌발발생_리포트_" + this.getCurrentDate() + ".xlsx";
        response = await this.occurEventChartDataDownloadExcelFunction(
          this.excelParam,
          fileName
        );
      } else if (
        this.searchType == "trafficInfo" &&
        this.changeChartType == "trafficCount"
      ) {
        var fileName =
          name + "_교통정보_차량대수_리포트_" + this.getCurrentDate() + ".xlsx";
        response = await this.trafficCountChartDataDownloadExcelFunction(
          this.excelParam,
          fileName
        );
      } else if (
        this.searchType == "trafficInfo" &&
        this.changeChartType == "trafficAvgSpeed"
      ) {
        var fileName =
          name + "_교통정보_평균속도_리포트_" + this.getCurrentDate() + ".xlsx";
        response = await this.trafficAvgSpeedChartDataDownloadExcelFunction(
          this.excelParam,
          fileName
        );
      }

      if (response == 200) {
        this.loader = false;
      }
    },
    getTotalCaseTrueStart(data) {
      return (
        data.item.case1trueStart +
        data.item.case2trueStart +
        data.item.case3trueStart +
        data.item.case4trueStart +
        // data.item.case6trueStart +
        data.item.case9trueStart
        // data.item.case11trueStart +
        // data.item.case12trueStart +
        // data.item.case13trueStart +
        // data.item.case14trueStart
      );
    },
    getTotalCaseFalseStart(data) {
      return (
        data.item.case1falseStart +
        data.item.case2falseStart +
        data.item.case3falseStart +
        data.item.case4falseStart +
        // data.item.case6falseStart +
        data.item.case9falseStart
        // data.item.case11falseStart +
        // data.item.case12falseStart +
        // data.item.case13falseStart +
        // data.item.case14falseStart
      );
    },
    getTotalCaseTrueEnd(data) {
      return (
        data.item.case1trueEnd +
        data.item.case2trueEnd +
        data.item.case3trueEnd +
        data.item.case4trueEnd +
        // data.item.case6trueEnd +
        data.item.case9trueEnd
        // data.item.case11trueEnd +
        // data.item.case12trueEnd +
        // data.item.case13trueEnd +
        // data.item.case14trueEnd
      );
    },
    getTotalCaseFalseEnd(data) {
      return (
        data.item.case1falseEnd +
        data.item.case2falseEnd +
        data.item.case3falseEnd +
        data.item.case4falseEnd +
        // data.item.case6falseEnd +
        data.item.case9falseEnd
        // data.item.case11falseEnd +
        // data.item.case12falseEnd +
        // data.item.case13falseEnd +
        // data.item.case14falseEnd
      );
    },
    async setOccurEventChartData() {
      // 기간별 돌발발생 수 차트 데이터 설정
      // 기존 categories 값 유지
      const occurEventChartOptions = this.occurEventChartOptions;
      this.occurEventData = [];
      this.occurEventData[0] = {
        name: "발생 수",
        data: this.occurEventChartData.map((item) => item.count),
      };
      // 기존 categories 값 다시 설정
      this.occurEventChartOptions = [];
      this.occurEventChartOptions = occurEventChartOptions;
    },
    async setTrafficCountChartData() {
      // 기간별 차량대수 차트 데이터 설정
      // 범례 설정 (categories)
      this.trafficCountChartOptions = {
        ...this.trafficCountChartOptions,
        xaxis: {
          ...this.trafficCountChartOptions.xaxis,
          categories: [
            ...new Set(
              this.trafficCountChartData.map((item) => item.detect_time)
            ),
          ],
        },
      };
      // 그래프 데이터 설정
      this.trafficCountData = []; // 빈 배열로 초기화
      // 1. 데이터를 link_code끼리 그룹화
      const groupedData = {};
      Object.values(this.trafficCountChartData).forEach((item) => {
        const key = item.link_code;
        if (!groupedData[key]) {
          groupedData[key] = {
            name: key,
            direction: item.direction,
            data: [],
          };
        }
        groupedData[key].data.push(item.sum);
      });
      // 2. 그룹화된 데이터를 trafficCountData에 추가
      Object.values(groupedData).forEach((group) => {
        var groupData = group.data;
        this.trafficCountData.push({
          name: `${group.direction} 차량대수`,
          data: groupData,
        });
      });
    },
    async setTrafficAvgSpeedChartData() {
      // 기간별 평균속도 차트 데이터 설정
      // 범례 설정 (categories)
      this.trafficAvgSpeedChartOptions = {
        ...this.trafficAvgSpeedChartOptions,
        xaxis: {
          ...this.trafficAvgSpeedChartOptions.xaxis,
          categories: [
            ...new Set(
              this.trafficAvgSpeedChartData.map((item) => item.detect_time)
            ),
          ],
        },
      };
      // 그래프 데이터 설정
      this.trafficAvgSpeedData = []; // 빈 배열로 초기화
      // 1. 데이터를 link_code끼리 그룹화
      const groupedData = {};
      Object.values(this.trafficAvgSpeedChartData).forEach((item) => {
        const key = item.link_code;
        if (!groupedData[key]) {
          groupedData[key] = {
            name: key,
            direction: item.direction,
            data: [],
          };
        }
        groupedData[key].data.push(item.avg_speed);
      });
      // 2. 그룹화된 데이터를 trafficCountData에 추가
      Object.values(groupedData).forEach((group) => {
        var groupData = group.data;
        this.trafficAvgSpeedData.push({
          name: `${group.direction} 평균속도`,
          data: groupData,
        });
      });
    },
    rEventTypeUpdateBtn() {
      // 기간 선택
      let dateFrom = null;
      let dateTo = dayjs().format("YYYY-MM-DD");
      if (this.changeDateType == "today") {
        dateFrom = dayjs().format("YYYY-MM-DD");
        if (
          this.searchType == "trafficInfo" &&
          this.changeChartType == "trafficAvgSpeed"
        ) {
          dateFrom = dayjs().startOf("day").format("YYYY-MM-DD HH:mm");
          dateTo = dayjs().format("YYYY-MM-DD HH:mm");
        }
      } else if (this.changeDateType == "week") {
        dateFrom = dayjs().subtract(7, "day").format("YYYY-MM-DD");
        if (
          this.searchType == "trafficInfo" &&
          this.changeChartType == "trafficAvgSpeed"
        ) {
          dateFrom = dayjs()
            .subtract(7, "day")
            .startOf("day")
            .format("YYYY-MM-DD HH:mm");
          dateTo = dayjs().format("YYYY-MM-DD HH:mm");
        }
      } else if (this.changeDateType == "month") {
        dateFrom = dayjs().subtract(1, "month").format("YYYY-MM-DD");
        if (
          this.searchType == "trafficInfo" &&
          this.changeChartType == "trafficAvgSpeed"
        ) {
          dateFrom = dayjs()
            .subtract(1, "month")
            .startOf("day")
            .format("YYYY-MM-DD HH:mm");
          dateTo = dayjs().format("YYYY-MM-DD HH:mm");
        }
      }
      this.mergeDate = `${dateFrom}${this.dateSeparator}${dateTo}`; // 기간
      this.searchStartDate = dateFrom;
      this.searchEndDate = dateTo;
      this.doFilter(); // 기간 선택 후 조회
    },
    setUpdateDate() {
      const mergeDateSplit = this.mergeDate.split(this.dateSeparator)
      this.searchStartDate = mergeDateSplit[0];
      if (mergeDateSplit[1] == null) {
        this.searchEndDate = this.searchStartDate;
      } else {
        this.searchEndDate = mergeDateSplit[1];
      }

      if (this.$i18n.locale == 'ko') {
        this.flatPickrConfig.locale = Korean
        this.timeFlatPickrConfig.locale = Korean
        this.dateSeparator = ' ~ '
      } else {
        this.flatPickrConfig.locale = English
        this.timeFlatPickrConfig.locale = English
        this.dateSeparator = ' to '
      }
      this.mergeDate = `${this.searchStartDate}${this.dateSeparator}${this.searchEndDate}`; // 기간
    },
  },  
  async created() {
    await this.getSelectBoxItems();
    await this.init();
    await this.refetchData();
  },
  computed: {
    getCurrentConfig() {
      if (
        this.searchType === "trafficInfo" &&
        this.changeChartType === "trafficAvgSpeed"
      ) {
        this.flatPickrConfigMode = "time";
        return this.timeFlatPickrConfig;
      } else {
        this.flatPickrConfigMode = "default";
        return this.flatPickrConfig;
      }
    },
  },
  watch: {
    getCurrentConfig: {
      handler(newConfig, oldConfig) {
        // 여기서 변경된 config에 대한 추가 작업 수행 가능
      },
      deep: true, // 객체의 내부 속성까지 감시할 경우 deep: true 설정
    },
    '$i18n.locale'(newLocale, oldLocale) {
      this.setUpdateDate();
      this.doFilter();
      this.searchOptions[0] = this.$t('label.L0082');
      this.searchOptions[1] = this.$t('label.L0083');
      this.CalendarIcon = this.$t('label.L0082');
      this.BarChartIcon = this.$t('label.L0083');
      this.occurEventChartOptions = {
        // 기간별 돌발발생 수 차트 옵션
        chart: {
          id: "occurEvent",
          zoom: { enabled: false },
          toolbar: {
            show: false,
          }
        },
        title: {
          text: this.$t('label.L0114'),
          align: "left",
        },
        plotOptions: {
          bar: {
            distributed: true,
          },
        },
        theme: {
          palette: "palette10", // upto palette10
        },
        xaxis: {
          categories: [
            this.$t('label.L0026'),
            this.$t('label.L0025'),
            this.$t('label.L0027'),
            this.$t('label.L0023'),
            this.$t('label.L0022'),
          ]
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value
                .toString()
                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            },
          },
        },
      }

    }
  },
  
};
</script>
<style scoped lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
div > b-badge {
  text-align: left !important;
  color: red;
}
.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
/* 검색 기간에서 시간, 년수에 사용자 입력 막기 위해 사용  */
.numInputWrapper .numInput {
  pointer-events: none !important;
}
.text-right {
  text-align: right;
}
</style>