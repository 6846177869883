import { ref, watch, computed, reactive, toRefs } from "@vue/composition-api";
import api from "@/libs/api.js";
// Notification
import { useToast } from "vue-toastification/composition";
import i18n from "@/libs/i18n.js"; // i18n 인스턴스 가져오기

export default function useUsersList() {
  const toast = useToast();

  const maintable = ref(null);

  const dateTableColumns = [
    {
      key: "idx",
      label: i18n.t("label.L0055"),
      thStyle: { width: "3%", verticalAlign: "middle" },
    },
    {
      key: "occurDate",
      label: i18n.t("label.L0144"),
      thStyle: { width: "7%", verticalAlign: "middle" },
    },
    {
      key: "direction",
      label: i18n.t("label.L0087"),
      thStyle: { width: "7%", verticalAlign: "middle" },
    },
    {
      key: "type",
      label: i18n.t("label.L0197"),
      thStyle: { width: "8%", verticalAlign: "middle" },
    },
    {
      key: "total",
      label: i18n.t("label.L0166"),
      thStyle: { width: "7%", verticalAlign: "middle" },
    },
    {
      key: "case9",
      label: i18n.t("label.L0026"),
      thStyle: { width: "7%", verticalAlign: "middle" },
      formatter: (value, key, item) => {
        return (
          "발생: " +
          item.case9Sum +
          "건 확인: " +
          item.case9SumChk +
          "건 정검지: " +
          item.case9true +
          "건 오검지: " +
          item.case9false +
          "건"
        );
      },
    },
    {
      key: "case4",
      label: i18n.t("label.L0025"),
      thStyle: { width: "7%", verticalAlign: "middle" },
      formatter: (value, key, item) => {
        return (
          "발생: " +
          item.case4Sum +
          "건 확인: " +
          item.case4SumChk +
          "건 정검지: " +
          item.case4true +
          "건 오검지: " +
          item.case4false +
          "건"
        );
      },
    },
    {
      key: "case1",
      label: i18n.t("label.L0027"),
      thStyle: { width: "7%", verticalAlign: "middle" },
      formatter: (value, key, item) => {
        return (
          "발생: " +
          item.case1Sum +
          "건 확인: " +
          item.case1SumChk +
          "건 정검지: " +
          item.case1true +
          "건 오검지: " +
          item.case1false +
          "건"
        );
      },
    },
    {
      key: "case3",
      label: i18n.t("label.L0023"),
      thStyle: { width: "7%", verticalAlign: "middle" },
      formatter: (value, key, item) => {
        return (
          "발생: " +
          item.case3Sum +
          "건 확인: " +
          item.case3SumChk +
          "건 정검지: " +
          item.case3true +
          "건 오검지: " +
          item.case3false +
          "건"
        );
      },
    },
    {
      key: "case2",
      label: i18n.t("label.L0022"),
      thStyle: { width: "7%", verticalAlign: "middle" },
      formatter: (value, key, item) => {
        return (
          "발생: " +
          item.case2Sum +
          "건 확인: " +
          item.case2SumChk +
          "건 정검지: " +
          item.case2true +
          "건 오검지: " +
          item.case2false +
          "건"
        );
      },
    },
    // {
    //   key: "case6",
    //   label: i18n.t("label.L0024"),
    //   thStyle: { width: "7%", verticalAlign: "middle" },
    //   formatter: (value, key, item) => {
    //     return (
    //       "발생: " +
    //       item.case6Sum +
    //       "건 확인: " +
    //       item.case6SumChk +
    //       "건 정검지: " +
    //       item.case6true +
    //       "건 오검지: " +
    //       item.case6false +
    //       "건"
    //     );
    //   },
    // },
    // {
    //   key: "case11",
    //   label: i18n.t("label.L0091"),
    //   thStyle: { width: "7%", verticalAlign: "middle" },
    //   formatter: (value, key, item) => {
    //     return (
    //       "발생: " +
    //       item.case11Sum +
    //       "건 확인: " +
    //       item.case11SumChk +
    //       "건 정검지: " +
    //       item.case11true +
    //       "건 오검지: " +
    //       item.case11false +
    //       "건"
    //     );
    //   },
    // },
    // {
    //   key: "case12",
    //   label: i18n.t("label.L0092"),
    //   thStyle: { width: "7%", verticalAlign: "middle" },
    //   formatter: (value, key, item) => {
    //     return (
    //       "발생: " +
    //       item.case12Sum +
    //       "건 확인: " +
    //       item.case12SumChk +
    //       "건 정검지: " +
    //       item.case12true +
    //       "건 오검지: " +
    //       item.case12false +
    //       "건"
    //     );
    //   },
    // },
    // {
    //   key: "case13",
    //   label: i18n.t("label.L0093"),
    //   thStyle: { width: "7%", verticalAlign: "middle" },
    //   formatter: (value, key, item) => {
    //     return (
    //       "발생: " +
    //       item.case13Sum +
    //       "건 확인: " +
    //       item.case13SumChk +
    //       "건 정검지: " +
    //       item.case13true +
    //       "건 오검지: " +
    //       item.case13false +
    //       "건"
    //     );
    //   },
    // },
    // {
    //   key: "case14",
    //   label: i18n.t("label.L0094"),
    //   thStyle: { width: "7%", verticalAlign: "middle" },
    //   formatter: (value, key, item) => {
    //     return (
    //       "발생: " +
    //       item.case14Sum +
    //       "건 확인: " +
    //       item.case14SumChk +
    //       "건 정검지: " +
    //       item.case14true +
    //       "건 오검지: " +
    //       item.case14false +
    //       "건"
    //     );
    //   },
    // },
  ];

  const techTableColumns = [
    {
      key: "idx",
      label: "번호",
      thStyle: {},
    },
    {
      key: "occurDate",
      label: "일자",
      thStyle: { width: "6%" },
    },
    { key: "equipId", label: "장비ID", thStyle: { width: "12%" } },
    { key: "techNm", label: "장비구분", thStyle: { width: "7%" } },
    { key: "total", label: "합계", thStyle: {} },
    {
      key: "case1",
      label: "정지차",
      formatter: (value, key, item) => {
        return (
          "발생: " +
          item.case1Sum +
          "건 확인: " +
          item.case1SumChk +
          "건 정검지: " +
          item.case1true +
          "건 오검지: " +
          item.case1false +
          "건"
        );
      },
    },
    {
      key: "case2",
      label: "보행자",
      formatter: (value, key, item) => {
        return (
          "발생: " +
          item.case2Sum +
          "건 확인: " +
          item.case2SumChk +
          "건 정검지: " +
          item.case2true +
          "건 오검지: " +
          item.case2false +
          "건"
        );
      },
    },
    {
      key: "case3",
      label: "역주행",
      formatter: (value, key, item) => {
        return (
          "발생: " +
          item.case3Sum +
          "건 확인: " +
          item.case3SumChk +
          "건 정검지: " +
          item.case3true +
          "건 오검지: " +
          item.case3false +
          "건"
        );
      },
    },
    {
      key: "case4",
      label: "급감속",
      thStyle: {},
      formatter: (value, key, item) => {
        return (
          "발생: " +
          item.case4Sum +
          "건 확인: " +
          item.case4SumChk +
          "건 정검지: " +
          item.case4true +
          "건 오검지: " +
          item.case4false +
          "건"
        );
      },
    },
    // {
    //   key: "case6",
    //   label: "화재",
    //   thStyle: {},
    //   formatter: (value, key, item) => {
    //     return (
    //       "발생: " +
    //       item.case6Sum +
    //       "건 확인: " +
    //       item.case6SumChk +
    //       "건 정검지: " +
    //       item.case6true +
    //       "건 오검지: " +
    //       item.case6false +
    //       "건"
    //     );
    //   },
    // },
    {
      key: "case9",
      label: "지정체",
      formatter: (value, key, item) => {
        return (
          "발생: " +
          item.case9Sum +
          "건 확인: " +
          item.case9SumChk +
          "건 정검지: " +
          item.case9true +
          "건 오검지: " +
          item.case9false +
          "건"
        );
      },
    },
    // {
    //   key: "case11",
    //   label: "사고음",
    //   formatter: (value, key, item) => {
    //     return (
    //       "발생: " +
    //       item.case11Sum +
    //       "건 확인: " +
    //       item.case11SumChk +
    //       "건 정검지: " +
    //       item.case11true +
    //       "건 오검지: " +
    //       item.case11false +
    //       "건"
    //     );
    //   },
    // },
    // {
    //   key: "case12",
    //   label: "경적음",
    //   formatter: (value, key, item) => {
    //     return (
    //       "발생: " +
    //       item.case12Sum +
    //       "건 확인: " +
    //       item.case12SumChk +
    //       "건 정검지: " +
    //       item.case12true +
    //       "건 오검지: " +
    //       item.case12false +
    //       "건"
    //     );
    //   },
    // },
    // {
    //   key: "case13",
    //   label: "급정거음",
    //   formatter: (value, key, item) => {
    //     return (
    //       "발생: " +
    //       item.case13Sum +
    //       "건 확인: " +
    //       item.case13SumChk +
    //       "건 정검지: " +
    //       item.case13true +
    //       "건 오검지: " +
    //       item.case13false +
    //       "건"
    //     );
    //   },
    // },
    // {
    //   key: "case14",
    //   label: "노이즈",
    //   formatter: (value, key, item) => {
    //     return (
    //       "발생: " +
    //       item.case14Sum +
    //       "건 확인: " +
    //       item.case14SumChk +
    //       "건 정검지: " +
    //       item.case14true +
    //       "건 오검지: " +
    //       item.case14false +
    //       "건"
    //     );
    //   },
    // },
  ];

  const directionTableColumns = [
    {
      key: "idx",
      label: "번호",
      thStyle: {},
    },
    {
      key: "occurDate",
      label: "일자",
      thStyle: { width: "6%" },
    },
    { key: "direction", label: "방면", thStyle: { width: "7%" } },
    { key: "total", label: "합계", thStyle: {} },
    {
      key: "case1",
      label: "정지차",
      formatter: (value, key, item) => {
        return (
          "발생: " +
          item.case1Sum +
          "건 확인: " +
          item.case1SumChk +
          "건 정검지: " +
          item.case1true +
          "건 오검지: " +
          item.case1false +
          "건"
        );
      },
    },
    {
      key: "case2",
      label: "보행자",
      formatter: (value, key, item) => {
        return (
          "발생: " +
          item.case2Sum +
          "건 확인: " +
          item.case2SumChk +
          "건 정검지: " +
          item.case2true +
          "건 오검지: " +
          item.case2false +
          "건"
        );
      },
    },
    {
      key: "case3",
      label: "역주행",
      formatter: (value, key, item) => {
        return (
          "발생: " +
          item.case3Sum +
          "건 확인: " +
          item.case3SumChk +
          "건 정검지: " +
          item.case3true +
          "건 오검지: " +
          item.case3false +
          "건"
        );
      },
    },
    {
      key: "case4",
      label: "급감속",
      thStyle: {},
      formatter: (value, key, item) => {
        return (
          "발생: " +
          item.case4Sum +
          "건 확인: " +
          item.case4SumChk +
          "건 정검지: " +
          item.case4true +
          "건 오검지: " +
          item.case4false +
          "건"
        );
      },
    },
    // {
    //   key: "case6",
    //   label: "화재",
    //   thStyle: {},
    //   formatter: (value, key, item) => {
    //     return (
    //       "발생: " +
    //       item.case6Sum +
    //       "건 확인: " +
    //       item.case6SumChk +
    //       "건 정검지: " +
    //       item.case6true +
    //       "건 오검지: " +
    //       item.case6false +
    //       "건"
    //     );
    //   },
    // },
    {
      key: "case9",
      label: "지정체",
      formatter: (value, key, item) => {
        return (
          "발생: " +
          item.case9Sum +
          "건 확인: " +
          item.case9SumChk +
          "건 정검지: " +
          item.case9true +
          "건 오검지: " +
          item.case9false +
          "건"
        );
      },
    },
    // {
    //   key: "case11",
    //   label: "사고음",
    //   formatter: (value, key, item) => {
    //     return (
    //       "발생: " +
    //       item.case11Sum +
    //       "건 확인: " +
    //       item.case11SumChk +
    //       "건 정검지: " +
    //       item.case11true +
    //       "건 오검지: " +
    //       item.case11false +
    //       "건"
    //     );
    //   },
    // },
    // {
    //   key: "case12",
    //   label: "경적음",
    //   formatter: (value, key, item) => {
    //     return (
    //       "발생: " +
    //       item.case12Sum +
    //       "건 확인: " +
    //       item.case12SumChk +
    //       "건 정검지: " +
    //       item.case12true +
    //       "건 오검지: " +
    //       item.case12false +
    //       "건"
    //     );
    //   },
    // },
    // {
    //   key: "case13",
    //   label: "급정거음",
    //   formatter: (value, key, item) => {
    //     return (
    //       "발생: " +
    //       item.case13Sum +
    //       "건 확인: " +
    //       item.case13SumChk +
    //       "건 정검지: " +
    //       item.case13true +
    //       "건 오검지: " +
    //       item.case13false +
    //       "건"
    //     );
    //   },
    // },
    // {
    //   key: "case14",
    //   label: "노이즈",
    //   formatter: (value, key, item) => {
    //     return (
    //       "발생: " +
    //       item.case14Sum +
    //       "건 확인: " +
    //       item.case14SumChk +
    //       "건 정검지: " +
    //       item.case14true +
    //       "건 오검지: " +
    //       item.case14false +
    //       "건"
    //     );
    //   },
    // },
  ];

  const trafficCountTableColumns = [
    { key: "idx", label: "번호" },
    { key: "trafficCount_occurDate", label: "일자" },
    { key: "trafficCount_direction", label: "방면" },
    { key: "count", label: "차량대수" },
  ];

  const trafficAvgSpeedTableColumns = [
    { key: "idx", label: "번호" },
    { key: "trafficAvgSpeed_occurDate", label: "일자" },
    { key: "trafficCount_direction", label: "방면" },
    { key: "speed", label: "평균속도" },
  ];

  // const changeOcrnDt = (value) => {
  //   const year = value.slice(0, 4);
  //   const month = value.slice(4, 6);
  //   const day = value.slice(6, 8);
  //   const hour = value.slice(8, 10);
  //   return `${year}-${month}-${day} ${hour}`;
  // };

  //   selectedEventTypeString : {
  //     0 : () => this.$t("event.test.none"),
  //     1 : () => this.$t('dashboard.event.stop'),
  //     2 : () => this.$t('dashboard.event.pedestrian'),
  //     3 : () => this.$t('dashboard.event.reverse'),
  //     9 : () => this.$t('dashboard.event.congestion'),
  //     999 :  () => this.$t('dashboard.event.title')
  // },
  // event.position.toFixed(2)
  const rowNum = ref(10);
  const totalRows = ref(0);
  const currentPage = ref(1);
  const itemLength = ref(0);
  const perPageOptions = [10, 50, 100];
  const filter = ref("");
  const searchEndDate = ref(null);
  const searchStartDate = ref(null);
  const searchBound = ref("ALL"); //방면
  const tunnelCodeOptions = ref([]); // 터널명 셀렉트 박스
  const tunnelCodeStatus = ref(""); // 터널명
  const eventStatus = ref(999); //돌발현황
  const realEventStatus = ref(999); //실제돌발현황
  const judgment = ref(999); //판정 결과
  const searchType = ref("occurTime"); //판정 결과
  const linkCode = ref(""); //링크코드?
  const occurEventChartData = ref([]);
  const trafficCountStatisticsList = ref([]);
  const trafficCountChartData = ref([]);
  const trafficAvgSpeedChartData = ref([]);
  const changeChartType = ref("trafficCount");
  const utils = {
    mergeJson: mergeJson,
    downloadFile: downloadFile,
  };
  const dataMeta = computed(() => {
    const localItemsCount = maintable.value
      ? maintable.value.localItems.length
      : 0;
    return {
      from: rowNum.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: rowNum.value * (currentPage.value - 1) + localItemsCount,
      of: totalRows.value,
    };
  });

  const refetchData = () => {
    if (maintable.value) {
      maintable.value.refresh();
    }
  };

  watch([currentPage, rowNum], () => {
    refetchData();
  });

  const getSelectBoxItems = async () => {
    try {
      let selectBoxItems = []; // 셀렉트 박스값 넣을 변수 초기화

      // 셀렉트 박스 값 백엔드 호출
      const response = await api.getSelectBoxItems();
      // 셀렉트 박스에 값 세팅
      const { data } = response.data;
      for (const item of data.selectBoxItems) {
        const selectBoxItem = {
          text: item.location,
          data: item.tunnelcode,
        };
        selectBoxItems.push(selectBoxItem);
      }
      tunnelCodeOptions.value = selectBoxItems;
      // 첫번째 터널로 터널명 초기 설정
      tunnelCodeStatus.value = selectBoxItems[0];
    } catch (err) {
      console.log(err);
    }
  };

  const getList = async () => {

    for (var i = 0; i < dateTableColumns.length; i++) {
      var reportTableColumns = dateTableColumns[i];
      if(i==0){
        reportTableColumns.label = i18n.t("label.L0055");
      }
      if(i==1){
        reportTableColumns.label = i18n.t("label.L0144");
      }
      if(i==2){
        reportTableColumns.label = i18n.t("label.L0087");
      }
      if(i==3){
        reportTableColumns.label = i18n.t("label.L0197");
      }
      if(i==4){
        reportTableColumns.label = i18n.t("label.L0166");
      }
      if(i==5){
        reportTableColumns.label = i18n.t("label.L0026");
      }
      if(i==6){
        reportTableColumns.label = i18n.t("label.L0025");
      }
      if(i==7){
        reportTableColumns.label = i18n.t("label.L0027");
      }
      if(i==8){
        reportTableColumns.label = i18n.t("label.L0023");
      }
      if(i==9){
        reportTableColumns.label = i18n.t("label.L0022");
      }
    }
    

    try {
      if (
        !tunnelCodeStatus.value.data ||
        tunnelCodeStatus.value.data.length === 0
      ) {
        await getSelectBoxItems();
        // console.error("tunnelCodeStatus.value.data is not properly set.");
        return;
      }
      if (searchType.value == "occurTime") {
        const response = await api.getStatisticsList({
          searchType: searchType.value,
          tunnelCode: tunnelCodeStatus.value.data,
          page: currentPage.value,
          pageSize: rowNum.value,
          startDate: searchStartDate.value,
          endDate: searchEndDate.value,
        });
        const { data } = response.data;
        const { page, total } = data.paging;

        currentPage.value = page;
        totalRows.value = total;
        itemLength.value = data.statisticsList.length;
        return data.statisticsList;
      } else if (
        searchType.value == "trafficInfo" &&
        changeChartType.value == "trafficCount"
      ) {
        const response = await api.getTrafficCountStatisticsList({
          searchType: searchType.value,
          tunnelCode: tunnelCodeStatus.value.data,
          page: currentPage.value,
          pageSize: rowNum.value,
          startDate: searchStartDate.value,
          endDate: searchEndDate.value,
        });
        const { data } = response.data;
        const { page, total } = data.paging;

        currentPage.value = page;
        totalRows.value = total;
        itemLength.value = data.trafficCountStatisticsList.length;
        return data.trafficCountStatisticsList;
      } else if (
        searchType.value == "trafficInfo" &&
        changeChartType.value == "trafficAvgSpeed"
      ) {
        const response = await api.getTrafficAvgSpeedStatisticsList({
          searchType: searchType.value,
          tunnelCode: tunnelCodeStatus.value.data,
          page: currentPage.value,
          pageSize: rowNum.value,
          startDate: searchStartDate.value,
          endDate: searchEndDate.value,
        });
        const { data } = response.data;
        const { page, total } = data.paging;

        currentPage.value = page;
        totalRows.value = total;
        itemLength.value = data.trafficAvgSpeedStatisticsList.length;
        return data.trafficAvgSpeedStatisticsList;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getOccurEventChartData = async () => {
    try {
      const response = await api.getOccurEventChartData({
        tunnelCode: tunnelCodeStatus.value.data,
        startDate: searchStartDate.value,
        endDate: searchEndDate.value,
      });
      const { data } = response.data;
      occurEventChartData.value = data.occurEventChartData;

      return response;
    } catch (err) {
      console.log(err);
    }
  };

  const getTrafficCountChartData = async () => {
    try {
      const response = await api.getTrafficCountChartData({
        searchType: searchType.value,
        tunnelCode: tunnelCodeStatus.value.data,
        startDate: searchStartDate.value,
        endDate: searchEndDate.value,
      });
      const { data } = response.data;
      trafficCountChartData.value = data.trafficCountChartData;

      return response;
    } catch (err) {
      console.log(err);
    }
  };

  const getTrafficAvgSpeedChartData = async () => {
    try {
      const response = await api.getTrafficAvgSpeedChartData({
        searchType: searchType.value,
        tunnelCode: tunnelCodeStatus.value.data,
        startDate: searchStartDate.value,
        endDate: searchEndDate.value,
      });
      const { data } = response.data;
      trafficAvgSpeedChartData.value = data.trafficAvgSpeedChartData;
      return response;
    } catch (err) {
      console.log(err);
    }
  };

  const occurEventChartDataDownloadExcelFunction = async (params, name) => {
    try {
      const response = await api.downloadOccurEventChartDataExcel(params);
      await utils.downloadFile(response.data, name);
      return response.status;
    } catch (err) {
      console.log(err);
    }
  };

  const trafficCountChartDataDownloadExcelFunction = async (params, name) => {
    try {
      const response = await api.downloadTrafficCountChartDataExcel(params);
      await utils.downloadFile(response.data, name);
      return response.status;
    } catch (err) {
      console.log(err);
    }
  };

  const trafficAvgSpeedChartDataDownloadExcelFunction = async (
    params,
    name
  ) => {
    try {
      const response = await api.downloadTrafficAvgSpeedChartDataExcel(params);
      await utils.downloadFile(response.data, name);
      return response.status;
    } catch (err) {
      console.log(err);
    }
  };

  const deleteFunction = async (data) => {
    api.removeSchedule(data);
  };
  const downLoadFunction = async (data) => {
    const response = await api.downloadVideo({
      no: data.item.no,
      d_event_type: data.item.dEventType,
      rec_video_url: data.item.recVideoUrl,
    });
    return response;
  };
  const updateFunction = async (arr) => {
    for (let i = 0; i < arr.length; i++) {
      let event = arr[i];
      await api.updateEvent(event.no, event);
    }
  };
  const updateRowFunction = async (item) => {
    let data = {};
    data.rEventType = item.rEventType;
    data.confirmType = item.confirmType;
    data.memo = item.memo;
    const response = await api.updateEvent(item.no, data);
    return response;
  };
  const downloadExcelFunction = async (params, name) => {
    try {
      const response = await api.downloadStatisticExcel(params);
      await utils.downloadFile(response.data, name);
      return response.status;
    } catch (err) {}
  };

  function mergeJson(source, target) {
    for (var key in target) {
      if (source.hasOwnProperty(key)) {
        var d = source[key];
        var td = target[key];
        if (typeof d == "object" && typeof td == "object") {
          if (Array.isArray(d)) {
            if (Array.isArray(td)) {
              d = d.concat(td);
            } else {
              d.push(td);
            }
          } else {
            d = mergeJson(d, td);
          }
        } else {
          source[key] = target[key];
        }
      } else {
        source[key] = target[key];
      }
    }

    return source;
  }

  function downloadFile(data, fileName) {
    let blob = new Blob([data]);
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //IE
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", fileName);
      link.click();
      link.remove();
    }
  }
  return {
    deleteFunction,
    refetchData,
    getList,
    getOccurEventChartData,
    occurEventChartData,
    trafficCountStatisticsList,
    getTrafficCountChartData,
    trafficCountChartData,
    getTrafficAvgSpeedChartData,
    trafficAvgSpeedChartData,
    dateTableColumns,
    techTableColumns,
    directionTableColumns,
    trafficCountTableColumns,
    trafficAvgSpeedTableColumns,
    rowNum,
    totalRows,
    currentPage,
    dataMeta,
    perPageOptions,
    filter,
    searchEndDate,
    searchStartDate,
    maintable,
    updateFunction,
    tunnelCodeOptions,
    tunnelCodeStatus,
    searchBound,
    eventStatus,
    realEventStatus,
    judgment,
    searchType,
    linkCode,
    downloadExcelFunction,
    occurEventChartDataDownloadExcelFunction,
    trafficCountChartDataDownloadExcelFunction,
    trafficAvgSpeedChartDataDownloadExcelFunction,
    itemLength,
    downLoadFunction,
    updateRowFunction,
    getSelectBoxItems,
    changeChartType,
  };
}
